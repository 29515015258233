<template>
  <v-main>
    <router-view />
  </v-main>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
  }
</script>

<style lang="scss">
.v-main__wrap {
  .container--fluid {
    padding-top: 1.5rem
  }
}
// .v-main__wrap
//   .container--fluid
//     padding-left: 15px // USED TO BE 60px left right
//     padding-right: 15px
//     padding-top: 40px

// .v-application .headline
//   font-size: 25px !important
//   padding-bottom: 0

// .v-application .black--text
//   color: #333 !important

// .v-application .small
//   font-weight: 300
//   line-height: 2rem
//   small
//     font-weight: 400

// @media(max-width:960px)
//   .v-main__wrap
//     .container--fluid
//       padding-left: 15px
//       padding-right: 15px

</style>

